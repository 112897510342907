import React, { Component } from 'react';
import * as partnerHttpServices from '../services/services';
import Swal from 'sweetalert2'
import Header from '../page/header';
import {
    Card,
    Col,
    Navbar,
    Button,
    Container,
    Form,
} from 'react-bootstrap';

export default class Board extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            phone: '',
            massage: '',
            text: null,
            textdata: null,
            alttext: null,
            image: null,
            title: null,
            single: 0,
            h_member: 0,
            n_member: 0,

            checked: false
        })
    }

    gettoken() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let token = user_info.token
        return token
    }
    getrole() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let role = user_info.data.role
        return role
    }
    componentDidMount() {
        if (this.getrole() === 0) {

            window.scrollTo(0, 0)
        } else {
            this.props.history.push('/login')
        }

    }

    onSearchChange = e => {
        this.setState({
            search: e.target.value
        });
    }
    handleChange = e => {
        console.log(e.target.value)
        this.setState({
            text: e.target.value
        });
    }
    handleSubmitstar = (e) => {
        e.preventDefault();
        const jsonData2 = {
            alttext: this.state.alttext,
            textdata: this.state.textdata,
            image: this.state.image,
            title: this.state.title,
            phone: this.state.search,
            test: this.state.single,
            h_member: this.state.h_member,
            n_member: this.state.n_member,
        }
        console.log(jsonData2)
        partnerHttpServices.boardcaststar(jsonData2)
            .then((response) => {
                if (response.data.success) {
                    Swal.fire(
                        'Boardcast Success!',
                        'You Post Boardcast Success!',
                        'success'
                    )
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }
            })
    };
    handleSubmit = (e) => {
        e.preventDefault();

        const jsonData = {
            phone: this.state.search,
            message: this.state.text,
            single: this.state.single,
            h_member: this.state.h_member,
            n_member: this.state.n_member,
        }

        partnerHttpServices.boardcast(jsonData)
            .then((response) => {
                if (response.data.success) {
                    console.log('test')
                    Swal.fire(
                        'Boardcast Success!',
                        'You Post Boardcast Success!',
                        'success'
                    )
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }
            })
    }
    checkSingle = () => {
        this.state.single === 1 ? this.setState({ single: 0 }) : this.setState({ single: 1 })
    }
    checkH_member = () => {
        this.state.h_member === 1 ? this.setState({ h_member: 0 }) : this.setState({ h_member: 1 })
        if (this.state.n_member === 0 || this.state.h_member === 0) {
            this.setState({
                checked: true
            })
        }
    }
    checkN_member = () => {
        this.state.n_member === 1 ? this.setState({ n_member: 0 }) : this.setState({ n_member: 1 })
        // if(this.state.n_member===1 || this.state.h_member ===1){
        //     this.setState({
        //         checked: true
        //     })
        // }
    }
    render() {
        if (localStorage.getItem('user') != null) {
            if (this.getrole() === 0) {
                console.log(this.state.testV)
                return (
                    <Container>
                        <div className='header'>
                            <Header />
                        </div>
                        <Card>
                            <div className='boardcast'>
                                <Container>
                                    <h2> Boardcast Package</h2>
                                    <br></br>

                                    <Form.Check
                                        aria-label="option 1"
                                        label="รายบุคคล"
                                        onChange={this.checkSingle}
                                    />
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formNumber">
                                            {/* <Form.Label style={{ marginLeft: 10 }}>Phone Number</Form.Label> */}
                                            <Form.Label>กรอกเบอร์โทรศัพท์</Form.Label>
                                            <Form.Control
                                                onChange={this.onSearchChange}
                                                placeholder="Enter phone number"
                                                style={{ width: 300 }}
                                            // style={{ width: 100, marginRight: 45, color: 'white', textDecorationLine: 'none' }}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Form.Check
                                        aria-label="option 2"
                                        label="มีแพ็กเกจ"
                                        onChange={this.checkH_member}

                                    />
                                    <Form.Check
                                        aria-label="option 3"
                                        label="ไม่มีแพ็กเกจ"
                                        onChange={this.checkN_member}
                                    />

                                    <br></br>
                                    <Card>
                                        <div className='boardcast'>

                                            <Form.Label>Massage</Form.Label>

                                            <form onSubmit={this.handleSubmit}>
                                                <textarea rows="10" cols="75" value={this.state.value} onChange={this.handleChange} placeholder=" Type a massage" />
                                                <br></br>
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </form>
                                        </div>
                                    </Card>
                                </Container>
                            </div>
                        </Card>
                        <br></br>
                        <Card>
                            <div className='boardcast'>
                                <Container>
                                    <h2> Boardcast Moving stars</h2>
                                    <br></br>
                                    <Form.Check
                                        aria-label="option 1"
                                        label="ทดสอบ"
                                        onChange={this.checkSingle}
                                    />
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formNumber">
                                            {/* <Form.Label style={{ marginLeft: 10 }}>Phone Number</Form.Label> */}
                                            <Form.Label>กรอกเบอร์โทรศัพท์</Form.Label>
                                            <Form.Control
                                                onChange={this.onSearchChange}
                                                placeholder="Enter phone number"
                                                style={{ width: 300 }}
                                            // style={{ width: 100, marginRight: 45, color: 'white', textDecorationLine: 'none' }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Form.Check
                                        aria-label="option 2"
                                        label="มีแพ็กเกจ"
                                        onChange={this.checkH_member}

                                    />
                                    <Form.Check
                                        aria-label="option 3"
                                        label="ไม่มีแพ็กเกจ"
                                        onChange={this.checkN_member}
                                    />
                                    <br></br>
                                    <Card>
                                        <div className='boardcast'>
                                            <Form onSubmit={this.handleSubmitstar}>
                                                <Form.Group className="mb-3" controlId="alttext">
                                                    <Form.Label>Alttext</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter alttext" onChange={(e) => this.setState({ alttext: e.target.value })} />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="image">
                                                    <Form.Label>Image URL</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Image" onChange={(e) => this.setState({ image: e.target.value })} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="title">
                                                    <Form.Label>Title </Form.Label>
                                                    <Form.Text className="text-muted">
                                                    * Max 40 Character
                                                    </Form.Text>
                                                    <Form.Control type="text" placeholder="Enter Title" onChange={(e) => this.setState({ title: e.target.value })} maxlength="40" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="textdata">
                                                    <Form.Label>Text </Form.Label>
                                                    <Form.Text className="text-muted">
                                                    * Max 60 Character
                                                    </Form.Text>
                                                    <Form.Control type="text" placeholder=" Enter Text" onChange={(e) => this.setState({ textdata: e.target.value })} maxlength="60" />
                                                </Form.Group>
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </Form>
                                        </div>
                                    </Card>

                                </Container>
                            </div>
                        </Card>

                    </Container>

                )
            }
            else {
                window.location = "/login";

            }

        }
        else {
            window.location = "/login";
        }
    }

}
