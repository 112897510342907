import React, { Component } from 'react';
import * as partnerHttpServices from '../services/services';
import Swal from 'sweetalert2'
import {
    Col,
    Row,
    Navbar,
    Button,
    Container,
    Form,
} from 'react-bootstrap';
import Header from '../page/header';
export default class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            search: '',
            status: '',
        })
    }

    gettoken() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let token = user_info.token
        return token
    }
    getrole() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let role = user_info.data.role
        return role
    }
    componentDidMount() {
        if(this.getrole() === 0){

            window.scrollTo(0, 0)
        }else{
            this.props.history.push('/login')  
        }
    }

    onSearchChange = e => {
        this.setState({
            search: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('search code: ', this.state.search)

        partnerHttpServices.getCodeStatus(this.state.search)
            .then((res) => {
                if (res.data.success) {
                    // console.log(res)
                    this.setState({
                        status: res.data.data[0].status,
                    });

                } else {
                    alert('Code not found!')
                    window.location.reload()
                }
            })
    };

    handleEdit = (e) => {
        e.preventDefault();
        console.log('edit')

        const jsonData = {
            search: this.state.search,
            status: this.state.status,
        }
        // console.log(jsonData)

        partnerHttpServices.editCodeStatus(jsonData)
            .then((response) => {
                if (response.data.success) {
                    console.log('edit code success', response)
                    Swal.fire(
                        'Edit Success!',
                        'You clicked the button!',
                        'success'
                      )

                } else {
                    console.log('edit code fail', response)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                      })
                }
            })
    }

    onLogout() {
        localStorage.removeItem('user')
        this.props.history.push('/login')
    }

    render() {
        if (localStorage.getItem('user') != null) {
            if (this.getrole() === 0) {
        return (
            <div className="container">
                        <div className='header'>
                            <Header />
                        </div>
                <h2 > Edit Codes </h2>
                <br></br>

                <Form onSubmit={this.handleSubmit}>
                    <Row >
                        <Col >
                            <Form.Group className="mb-3" controlId="formNumber">
                                {/* <Form.Label style={{ marginLeft: 10 }}>Phone Number</Form.Label> */}
                                <Form.Label>Discount code</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    onChange={this.onSearchChange}
                                    placeholder="Code"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button style={{ marginLeft: 10, marginTop: 30 }} variant="primary" type="submit" id="submit">ค้นหา</Button>
                        </Col>
                    </Row>
                </Form>

                <br></br>
                <br></br>
                <Form onSubmit={this.handleEdit}>
                    {/* <fieldset disabled> */}
                    <fieldset>
                        {/* <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                onChange={(e) => this.setState({ status: e.target.value })}
                                value={this.state.status}
                                style={{ width: 300 }} />
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                            <Form.Label >Status</Form.Label>
                            <Form.Select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })} style={{ width: 300 }} >
                                <option value='null'></option>
                                <option value="0">ยังไม่ได้เปิดใช้</option>
                                <option value="1">เปิดการใช้งานแล้ว</option>
                            </Form.Select>
                        </Form.Group>

                        <br></br>
                        <Button type="submit">Edit</Button>
                    </fieldset>
                </Form>
            </div>

        )
    }
    else {
        window.location = "/login";

    }

}
else {
    window.location = "/login";
}
    }

}
