import React, { Component } from 'react';
import * as partnerHttpServices from '../services/services';
import Swal from 'sweetalert2'
import {
    Card,
    Col,
    Row,
    Navbar,
    Button,
    Container,
    Form,
} from 'react-bootstrap';
import Header from '../page/header';
import { WatDatePicker } from "thaidatepicker-react";
import Data from '../data/provinces.json'
import utilTime from '../utils/time';

export default class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            province_id: '',
            uid: '',
            search: '',
            name: '',
            surname: '',
            phone_number: '',
            birth_date: null,
            birth_hour: '',
            birth_minute: '',
            gender: '',
            no_birth:'',
            start_date: '',
            exp_date: '',
            package_id: '',

        })
    }

    convertUTCDateToLocalDate(e) {
        let dates = new Date(e);
        var date = utilTime.convertTZ(dates, "Asia/Bangkok")
        let newdate = date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2) + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2);

        return newdate
    }

    gettoken() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let token = user_info.token
        return token
    }
    getrole() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let role = user_info.data.role
        return role
    }
    componentDidMount() {
        if (this.getrole() === 0) {

            window.scrollTo(0, 0)
        } else {
            this.props.history.push('/login')
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('search: ', this.state.search)

        partnerHttpServices.getData(this.state.search)
            .then((res) => {
                if (res.data.success) {
                    // console.log(res)

                    this.setState({
                        uid: res.data.data[0].user_id,
                        name: res.data.data[0].name,
                        surname: res.data.data[0].surname,
                        phone_number: res.data.data[0].phone_number,
                        birth_date: res.data.data[0].birth_date,
                        province_id: res.data.data[0].province_id,
                        birth_hour: res.data.data[0].birth_hour,
                        birth_minute: res.data.data[0].birth_minute,

                        // new
                        gender: res.data.data[0].gender,
                        no_birth : res.data.data[0].no_birth_time,
                        start_date: res.data.data[0].start_date,
                        exp_date: res.data.data[0].exp_date,
                        package_id: res.data.data[0].package_id,

                    });



                } else {
                    alert('Phone number not found!')
                    window.location.reload()
                }
            })
    };

    handleEdit = (e) => {
        e.preventDefault();

        const jsonData = {
            uid: this.state.uid,
            name: this.state.name,
            surname: this.state.surname,
            phone_number: this.state.phone_number,
            province_id: this.state.province_id,
            birth_date: this.state.birth_date,
            birth_hour: this.state.birth_hour,
            birth_minute: this.state.birth_minute,

            gender: this.state.gender,
            no_birth : this.state.no_birth,
            start_date: this.state.start_date,
            exp_date: this.state.exp_date,
            package_id: this.state.package_id,
        }

        // console.log(jsonData)

        partnerHttpServices.editData(jsonData)
            .then((response) => {
                if (response.data.success) {
                    console.log('edit success', response)
                    Swal.fire(
                        'Edit Success!',
                        'You clicked the button!',
                        'success'
                    )
                } else {
                    console.log('edit fail', response)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }
            })
    }

    onSearchChange = e => {
        this.setState({
            search: e.target.value
        });
    }

    onSearchChange2 = e => {
        this.setState({
            start_date: e.target.value
        });
    }

    handleWatDatePickerChange = (en, th) => {
        console.log(en);
        console.log(th);
        // setSelectedDate(en);

        this.setState({
            birth_date: en,
        });

    };

    handleStartDatePickerChange = (e) => {
        this.setState({
            start_date: e.target.value
        });
    };

    handleExpDatePickerChange = (e) => {
        this.setState({
            exp_date: e.target.value
        });
    };

    onLogout() {
        localStorage.removeItem('user')
        this.props.history.push('/login')
    }

    render() {
        if (localStorage.getItem('user') != null) {
            if (this.getrole() === 0) {
                return (
                    <Container>
                        <div className='header'>
                            <Header />
                        </div>
                        <div className='formeditcard'>
                            <Card>
                                <div className='formedit'>
                                    <h2> Edit Users </h2>
                                    <Form style={{ marginTop: '2rem' }} onSubmit={this.handleSubmit}>
                                        <Row >

                                            <Col >
                                                <Form.Group className="mb-3" controlId="formNumber">
                                                    {/* <Form.Label style={{ marginLeft: 10 }}>Phone Number</Form.Label> */}
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        onChange={this.onSearchChange}
                                                        placeholder="Number" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Button style={{ marginLeft: 10, marginTop: 30 }} variant="primary" type="submit" id="submit">ค้นหา</Button>
                                            </Col>

                                        </Row>

                                    </Form>
                                    <Form onSubmit={this.handleEdit}>
                                        {/* <fieldset disabled> */}

                                        <fieldset>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="disabledTextInput">Name</Form.Label>
                                                        <Form.Control id="disabledTextInput" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="disabledTextInput">Surname</Form.Label>
                                                        <Form.Control id="disabledTextInput" onChange={(e) => this.setState({ surname: e.target.value })} value={this.state.surname} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="disabledTextInput">Phone_number</Form.Label>
                                                        <Form.Control id="disabledTextInput" onChange={(e) => this.setState({ phone_number: e.target.value })} value={this.state.phone_number} />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label >Province</Form.Label>
                                                        {/* <Form.Select value="ชัยนาท"> { */}
                                                        <Form.Select value={this.state.province_id} onChange={(e) => this.setState({ province_id: e.target.value })}> {
                                                            // Data.PROVINCES.map((result) => (<option key={this.state.province_id} value={result.id}>{result.name_th}</option>))
                                                            Data.PROVINCES.map((result) => (<option key={result.id} value={result.id}>{result.name_th}</option>))

                                                        }
                                                        </Form.Select>
                                                    </Form.Group>


                                                    <Form.Group className="mb-3">
                                                        <Form.Label >Birth_date</Form.Label>
                                                        <WatDatePicker
                                                            value={this.state.birth_date}
                                                            onChange={this.handleWatDatePickerChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="disabledTextInput">Birth_hour</Form.Label>
                                                        <Form.Control id="disabledTextInput" onChange={(e) => this.setState({ birth_hour: e.target.value })} value={this.state.birth_hour} />
                                                    </Form.Group>

                                                </Col>
                                                {/* new */}
                                                <Col >

                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="disabledTextInput">Birth_minute</Form.Label>
                                                        <Form.Control id="disabledTextInput" onChange={(e) => this.setState({ birth_minute: e.target.value })} value={this.state.birth_minute} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label >No Birth Time</Form.Label>
                                                        <Form.Select
                                                            value={this.state.no_birth}
                                                            onChange={(e) => this.setState({ no_birth: e.target.value })}
                                                        //style={{ width: 250 }}
                                                        >
                                                            <option value='null'></option>
                                                            <option value="0">No</option>
                                                            <option value="1">Yes</option>
                                                        </Form.Select>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">

                                                        <Form.Label>Start_date</Form.Label>
                                                        <Form.Control type="datetime-local"
                                                            onChange={this.handleStartDatePickerChange}
                                                            value={this.state.start_date === null ? null : this.convertUTCDateToLocalDate(this.state.start_date)}
                                                        //style={{ width: 250 }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Exp_date</Form.Label>
                                                        <Form.Control type="datetime-local"
                                                            onChange={this.handleExpDatePickerChange}
                                                            value={this.state.exp_date === null ? null : this.convertUTCDateToLocalDate(this.state.exp_date)}
                                                        //style={{ width: 250 }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label >Gender</Form.Label>
                                                        <Form.Select
                                                            value={this.state.gender}
                                                            onChange={(e) => this.setState({ gender: e.target.value })}
                                                        //style={{ width: 250 }}
                                                        >
                                                            <option value='null'></option>
                                                            <option value="M">Male</option>
                                                            <option value="F">Female</option>
                                                        </Form.Select>
                                                    </Form.Group>


                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Package_id</Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => this.setState({ package_id: e.target.value })}
                                                            value={this.state.package_id}

                                                        //style={{ width: 250 }}
                                                        />
                                                    </Form.Group>

                                                    <Button type="submit" className='button-edit'>Edit</Button>
                                                </Col>

                                            </Row>
                                        </fieldset>
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </Container>

                )
            }
            else {
                window.location = "/login";

            }

        }
        else {
            window.location = "/login";
        }
    }

}
