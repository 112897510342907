import * as React from 'react';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Navbar,Container} from 'react-bootstrap';
import LogoImg from '../assets/images/logo_ganesha168_circle.png'
import Header from '../page/header';

const theme = createTheme();
const navigateTable = () => { window.location = "/table_admin"; };
const navigateEditUser = () => { window.location = "/edit_admin"; };
const navigateEditCode = () => { window.location = "/code_admin"; };
const navigateWallDetail = () => { window.location = "/wall_detail"; };
const navigateBoardcast = () => { window.location = "/boardcast"; };
const navigatePackage = () => { window.location = "/control_package"; };
const navigateTaxInvoice = () => { window.location = "/tax_invoice_table"; };
const navigatePlanetMove = () => {window.location = "/planet_move" ;};
const navigateZodiac = () => {window.location = "/zodiac" ;};
function getrole() {
    let user_info = JSON.parse(localStorage.getItem('user'))
    let role = user_info.data.role
    return role
}
export default function Menu() {
    if (localStorage.getItem('user') != null) {
        if (getrole() === 0) {
            return (
                <ThemeProvider theme={theme}>
                        <div className='header'>
                            <Header />
                        </div>
                    <main>
                        {/* Hero unit */}
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 10,
                                pb: 6,
                            }}
                        >
                            {/* <Container maxWidth="sm"> */}
                            <Container>
                                <Typography
                                    marginTop={-5}
                                    component="h1"
                                    variant="h2"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                >

                                    <div>
                                        <img src={LogoImg} className="loginLogo" width={300} height={300} alt="" />
                                    </div>
                                </Typography>

                                <br></br><br></br>

                                <Stack
                                    marginTop={-5}
                                    sx={{ pt: 4 }}
                                    direction="row"
                                    spacing={4}
                                    justifyContent="center"
                                >
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="contained" onClick={navigateTable}>Order page</Button>
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="outlined" onClick={navigateEditUser} >Edit users</Button>
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="outlined" onClick={navigateEditCode} >Edit codes</Button>
                                </Stack>
                                <Stack
                                    sx={{ pt: 4 }}
                                    direction="row"
                                    spacing={4}
                                    justifyContent="center"
                                >
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="outlined" onClick={navigateWallDetail}>wall_detail</Button>
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="outlined" onClick={navigateBoardcast} >Boardcast</Button>
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="outlined" onClick={navigatePackage} >control Package</Button>
                                </Stack>
                                <Stack
                                    sx={{ pt: 4 }}
                                    direction="row"
                                    spacing={4}
                                    justifyContent="center"
                                >
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="outlined" onClick={navigatePlanetMove}>planet move</Button>
                                    <Button style={{ height: '3.5rem', width: '10rem' }} variant="outlined" onClick={navigateZodiac}>Zodiac</Button>
                                </Stack>
                            </Container>
                        </Box>
                    </main>
                </ThemeProvider>
            );
        }
        else {
            window.location = "/login";

        }

    }
    else {
        window.location = "/login";
    }
}