import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App'
import Login from './components/login'
import Table from './components/table_partner'
import Admin from './components/table_admin'
import Edit from './components/edit_admin'
import Menu from './components/menu_page'
import PlanetMove from './components/planet_move';
import Zodiac from './components/zodiac'
import CodeEdit from './components/code_admin'
import WallDetail from './components/wall_detail'
import Boardcast from './components/boardcast'
import Package from './components/control_package'
import reportWebVitals from './reportWebVitals';
import ReportTaxInvoice from './components/tax_invoice_report'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/table" element={<Table />} />
      <Route path="/table_admin" element={<Admin />} />
      <Route path="/edit_admin" element={<Edit />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/code_admin" element={<CodeEdit />} />
      <Route path="/wall_detail" element={<WallDetail/>} />
      <Route path="/boardcast" element={<Boardcast/>} />
      <Route path="/control_package" element={<Package/>} />
      <Route path="/tax_invoice_report" element={<ReportTaxInvoice/>} />
      <Route path="/planet_move" element={<PlanetMove/>} />
      <Route path="/zodiac" element={<Zodiac/>} />
    </Routes>
  </BrowserRouter>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
