import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
    Navbar,
    Container,
} from 'react-bootstrap';

interface Props {
    window?: () => Window;
}

const drawerWidth = 240;

export default function DrawerAppBar(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function onLogout() {
        localStorage.removeItem('user')
        this.props.history.push('/login')
    }

    const drawer = (
            <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} >
                {/* sx={{ my: 2 ,bgcolor: 'primary.main'} */}
                <Typography variant="h6" sx={{ my: 2 }}>
                    Ganesha 168 Administrator
                </Typography>
                <Divider />
                <List>
                    <Button href="/menu" color="inherit">Menu</Button><br />
                    <Button href="/login" onClick={() => onLogout()} color="inherit">Sign Out</Button>
                </List>
            </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography
                        variant="h6"
                        component="div"
                        style={{ marginLeft: 150 }}
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        <Navbar variant="dark" >
                            <Container>
                                <Navbar.Brand href="/menu">
                                    <h3>Ganesha 168 Administrator</h3>
                                </Navbar.Brand>
                            </Container>
                        </Navbar>
                    </Typography>


                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Button href="/login" onClick={() => onLogout()} style={{ width: 100, marginRight: 45, color: 'white', textDecorationLine: 'none' }}>Sign Out</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer 
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

        </Box>
    );
}