import React, { Component } from 'react';
import * as partnerHttpServices from '../services/services';
import Swal from 'sweetalert2'
import {
    Card,
    Col,
    Row,
    Navbar,
    Button,
    Container,
    Form,
} from 'react-bootstrap';
import Header from '../page/header';
export default class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            search: '',
            status: '',
            status2: '',
        })
    }

    gettoken() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let token = user_info.token
        return token
    }
    getrole() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let role = user_info.data.role
        return role
    }
    componentDidMount() {
        if(this.getrole() === 0){
            partnerHttpServices.controlPackage().then((response) => {
                if (response.data.success) {
                    this.setState({ status: response.data.data ,status2: response.data.data})
                }
            }) 
        }else{
            this.props.history.push('/login')  
        }
    }

    onSearchChange = e => {
        this.setState({
            search: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
 
        const jsonData = {
            status: this.state.status,
        }
        partnerHttpServices.controlPackage(jsonData)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        status2:  response.data.data,
                    })
                    console.log(this.state.status2)
                    if(this.state.status==="1"){
                        Swal.fire(
                            'Open Package Success!',
                            'You Open Package 7 Days',
                            'success'
                          )
                    }else if(this.state.status==="0"){
                        Swal.fire(
                            'Close Package Success!',
                            'You Close Package 7 Days',
                            'success'
                          )   
                    }

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }
            })
    };
    onLogout() {
        localStorage.removeItem('user')
        this.props.history.push('/login')
    }
    render() {
        if (localStorage.getItem('user') != null) {
            if (this.getrole() === 0) {
        return (
            <div className="container">
                        <div className='header'>
                            <Header />
                        </div>
                <Card >       
                <div className='formedit'>                    
                <h2 > เปิด-ปิด Package</h2>
                <div className='card-package'>
                <Form onSubmit={this.handleSubmit}>
                    {/* <fieldset disabled> */}
                    <fieldset>
                        <Form.Group className="mb-3">
                            <Form.Label >เลือกเปิด-ปิด Free package 7 Day</Form.Label>
                            <Form.Select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })} style={{ width: 300 }} >
                                <option value="0">ปิดการสมัครแล้วได้package7วัน</option>
                                <option value="1">เปิดการสมัครแล้วได้package7วัน</option>
                            </Form.Select>
                        </Form.Group>
                      <div><span  style={{fontSize:20}}>status</span> {(() => {
                            if (this.state.status2 === 1) {
                               return <span style={{ color: 'green' }}>กำลังเปิดใช้งาน</span>
                            }
                            else {
                                return <span style={{ color: '#e74c3c' }}>ปิดใช้งาน</span>
                            }
                        })()}</div>
                        <br></br>
                        <Button type="submit">Apply</Button>
                    </fieldset>
                </Form>
                </div>
               </div>
                </Card>
            </div>

        )
    }
    else {
        window.location = "/login";

    }

}
else {
    window.location = "/login";
}
    }

}
