import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Card
} from "react-bootstrap";
import Header from '../page/header';
import * as planetServices from '../services/services'
import Swal from 'sweetalert2'


export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currData: [],
      editedRows : {},
    };
  }


  componentDidMount (){
    //moment2.tz.setDefault("Asia/Bangkok")
    planetServices.getPlanet()
    .then((res) => {
        if(res.data.success){
            let result = res.data.data
            let list_data = []
            result.map((item) => {
                list_data.push({
                    "id" : item.id,
                    "planet_name" : item.planet_name,
                    "prediction" : item.prediction,
                    "youtube_uri" : item.youtube_uri,
                    "date_move" : item.date_move,
                    "next_orbit" : item.next_orbit,
                    "current_orbit" : item.current_orbit
                })
            })
            console.log(list_data);
            this.setState({
                currData : list_data
            })
        }else{
            // alert('planet data not found!');
            // window.location.reload();
        }

    }).catch(error =>{
        console.log('error fetching data :',error);
    })
  }
  handleInputChange = (id, field, value) => {
    const { editedRows } = this.state;
    
    const updatedRow = { ...editedRows[id] };
    if (field === "date_move") {
      // Append the seconds and timezone offset to the datetime string
      const formattedDateTime = value + ":00.000Z";
      updatedRow[field] = formattedDateTime;
    } else {
      updatedRow[field] = value;
    }
    
    this.setState(prevState => ({
      editedRows: {
        ...prevState.editedRows,
        [id]: updatedRow
      }
    }));
  };

  handleSave = () => {
    const { editedRows, currData } = this.state;
  
    const updatedData = currData.map(item => {
      const editedRow = editedRows[item.id];
      if (editedRow) {
        return {
          ...item,
          ...editedRow
        };
      }
      return item;
    });
    
    const jsonData = updatedData.map(item => ({
        id: item.id,
        planet_name: item.planet_name,
        prediction: item.prediction,
        date_move: item.date_move,
        youtube_uri: item.youtube_uri,
        current_orbit : item.current_orbit,
        next_orbit : item.next_orbit
      }));
  
    planetServices.updatePlanet(jsonData)
      .then((res) => {
        if (res.data.success) {
          console.log('Edit success');
          Swal.fire(
            'Edit Success!',
            'You clicked the button!',
            'success'
          );
        } else {
          console.log('Edit fail', res);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="">Why do I have this issue?</a>'
          });
        }
      })
      .catch(error => {
        console.error('Error updating planet:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  
   
    this.setState({
      currData: updatedData,
      editedRows: {}
    });
  };

  render() {
    const { currData,editedRows } = this.state;

    return (
      <Container>
        <div className="header">
            <Header/>
        </div>
        <div className='formeditcard'>
        <Card>
        <div className='formedit'>
            <h2>Planet Move</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>เลขดาว</th>
              <th>ชื่อดาว</th>
              <th>คำทำนาย</th>
              <th>วันที่ดาวย้ายครั้งหน้า</th>
              <th>คลิปวีดีโอ</th>
              <th>โคจรปัจจุบัน</th>
              <th>โคจรที่จะถึง</th>
            </tr>
          </thead>
          <tbody>
            {currData.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>
                 {item.planet_name}
                </td>
                <td>
                <textarea
                    value={editedRows[item.id] && editedRows[item.id].prediction !== null ? editedRows[item.id].prediction : item.prediction || ''}
                    style={{width: "100%"}}
                    rows={3}
                    onChange={e =>
                     this.handleInputChange(item.id, "prediction", e.target.value)
                    }
                />
                </td>
                <td>
                <input
                type="datetime-local"
                style={{ width: "100%" }}
                value={editedRows[item.id]?.date_move ? editedRows[item.id].date_move.slice(0, 16) : (item.date_move ? item.date_move.slice(0, 16) : "")}
                onChange={e =>
                this.handleInputChange(item.id, "date_move", e.target.value)
                }
                />
                </td>
                <td>
                  <input
                    type="text"
                    value={editedRows[item.id] && editedRows[item.id].youtube_uri !== null ? editedRows[item.id].youtube_uri : item.youtube_uri || ''}
                    style={{width: "100%"}}
                    onChange={e =>
                      this.handleInputChange(item.id, "youtube_uri", e.target.value)
                    }
                  />
                </td>
                <td>
                    <select
                        style={{width: "100%"}}
                        value={editedRows[item.id]?.current_orbit || item.current_orbit}
                        onChange={e =>
                            this.handleInputChange(item.id,"current_orbit",e.target.value)
                        }
                    >
                        <option value='0'>ปกติ</option>
                        <option value='1'>พักร์</option>
                        <option value='2'>มนท์</option>
                        <option value='3'>เสริด</option>
                    </select>
                </td>
                <td>
                    <select
                        style={{width: "100%"}}
                        value={editedRows[item.id]?.next_orbit || item.next_orbit}
                        onChange={e =>
                            this.handleInputChange(item.id,"next_orbit",e.target.value)
                        }
                    >
                        <option value='0'>ปกติ</option>
                        <option value='1'>พักร์</option>
                        <option value='2'>มนท์</option>
                        <option value='3'>เสริด</option>
                    </select>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button variant="primary" onClick={this.handleSave}>
          บันทึก
        </Button>
        </div>
        </Card>
        </div>
      </Container>
    );
  }
}
