import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import Moment from 'moment';
import { Field, reduxForm, reset } from "redux-form";
import {
    Card,
    Col,
    Row,
    Navbar,
    Button,
    Nav,
    Container,
    Jumbotron,
    FormGroup,
    FormControl,
    Form,
    Table
} from "react-bootstrap";
import utilTime from '../utils/time';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getOrder } from "../services/services"
import * as partnerHttpServices from '../services/services';
import Header from '../page/header';
import { Outlet, Link } from "react-router-dom";
export default class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = ({ usersCollection: [], partner: 'All Partner', start_date: '', end_date: '', nameCollection: null });
    }
    state = {
        searchText: '',

    }
    gettoken() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let token = user_info.token
        return token
    }
    getrole() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let role = user_info.data.role
        return role
    }

    componentDidMount() {
        if (localStorage.getItem('user') != null) {
            if (this.getrole() === 0) {
                partnerHttpServices.getPartnerName().then((response) => {
                    if (response.data.success) {
                        this.setState({ nameCollection: response.data.data })
                    }
                })
                console.log((this.getrole()))
                partnerHttpServices.getOrderAdmin("", this.gettoken()).then((response) => {
                    if (response.data.success) {
                        this.setState({ usersCollection: response.data.data })
                    }
                })
            }
            else {
                this.props.history.push('/login')
            }
        } else {
            this.props.history.push('/login')
        }
        window.scrollTo(0, 0)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        this.setState({
            partner: data.get('partner'),
            start_date: data.get('start_date'),
            end_date: data.get('end_date'),
        });
        const jsonData = {
            //code: data.get('code'),
            partner: data.get('partner'),
            start_date: data.get('start_date'),
            end_date: data.get('end_date'),
            product: data.get('product'),
            bill: data.get('bill'),
            customer: data.get('customer'),
            type: data.get('type'),
        }
        if (localStorage.getItem('user') != null) {
            partnerHttpServices.filterOrderAdmin(jsonData, this.gettoken()).then((response) => {
                if (response.data.success) {
                    this.setState({ usersCollection: response.data.data })
                }
                else {
                    this.setState({ usersCollection: response.data.data })
                }
            })
        } else {
            this.props.history.push('/login')
        }
    };

    onSearchChange = e => {
        this.setState({
            searchText: e.target.value
        });
    }
    getReport = (e, id) => {
   
        console.log('test')



        
    }

            getSumOrder() {
                let sum = 0
        _.map(this.state.usersCollection, (data, index) => {
                sum = index + 1
            })
            return sum
    }
            getSumPrice() {
                let sum = 0
        _.map(this.state.usersCollection, data => {
                sum += Number((data.sum_price).toFixed(2))
            })
            return sum
    }
            getSumDiscount() {
                let sum = 0
        _.map(this.state.usersCollection, data => {
                sum += Number((data.discount).toFixed(2))
            })
            return sum.toFixed(2)
    }
            getSumPriceDis() {
                let sum = 0
        _.map(this.state.usersCollection, data => {
                sum += Number((data.money_received).toFixed(2))
            })
            return sum
    }
    onLogout() {
        localStorage.removeItem('user')
        this.props.history.push('/login')
    }
            render() {
        if (this.state.query_data === 1) {
            return (
            <div style={{ paddingLeft: 50, paddingRight: 50 }}>
                <div><h3 style={{ textAlign: 'center', marginTop: 150 }}>ไม่มีออเดอร์</h3></div>
            </div>
            )
        } else {
            const {handleSubmit} = this.props;
            return (
            <div className="container">
                <div className='header'>
                    <Header />
                </div>
                <h2 > Administrator </h2>
                <h3>ออเดอร์ทั้งหมด {this.getSumOrder()}</h3>
                <Form onSubmit={this.handleSubmit}>
                    <Row >
                        <Col >
                            <Form.Group controlId="partner">
                                <Form.Label style={{ marginLeft: 10 }}>Partner</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="partner"
                                    onChange={this.onSearchChange}
                                    style={{ width: 200 }}
                                >
                                    <option value=''></option>
                                    {_.map(this.state.nameCollection, (data, index) => (
                                        <option key={`options_key_${index}`}>{data.partner_name}</option>
                                    ))}
                                    {/* <option value=''></option>
                                        <option value="รายการ จิ้งจกทัก">จิ้งจกทัก</option>
                                        <option value="Atime">Atime</option>
                                        <option value="Ganesha">Ganesha</option>
                                        //<option value="1">Package</option> */}

                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col >
                            <Form.Group controlId="customer">
                                <Form.Label style={{ marginLeft: 10 }}>ประเภทสินค้า</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="type"
                                    onChange={this.onSearchChange}
                                    style={{ width: 120 }}
                                >
                                    <option value=''></option>
                                    <option value="0">Wallpaper</option>
                                    <option value="1">Package</option>
                                    <option value="2">Boxset</option>
                                    <option value="3">Card</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col >
                            <Form.Group controlId="customer">
                                <Form.Label style={{ marginLeft: 10 }}>customer</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="customer"
                                    onChange={this.onSearchChange}
                                    style={{ width: 100 }}
                                >
                                    <option value=''></option>
                                    <option value="MEEMON">MEEMON</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col >
                            <Form.Label style={{ marginLeft: 10 }}>วันที่เริ่มต้น</Form.Label>
                            <Form.Control type="date"
                                onChange={this.onSearchChange}
                                name="start_date"
                                ref={(input) => this.query = input}
                                placeholder="Search..."
                                style={{ width: 200, marginLeft: 10 }} />
                        </Col>
                        <Col >
                            <Form.Label style={{ marginLeft: 10 }}>วันที่สิ้นสุด</Form.Label>
                            <Form.Control type="date"
                                onChange={this.onSearchChange}
                                name="end_date"
                                ref={(input) => this.query = input}
                                placeholder="Search..."
                                style={{ width: 200, marginLeft: 10 }} />
                        </Col>
                        <Col >
                            <Form.Group controlId="bill">
                                <Form.Label style={{ marginLeft: 10 }}>การตัดบิล</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="bill"
                                    onChange={this.onSearchChange}
                                    style={{ width: 100 }}
                                >
                                    <option value=''></option>
                                    <option value="0">ยังไม่ได้ตัดบิล</option>
                                    <option value="1">ตัดบิลแล้ว</option>

                                </Form.Control>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Button style={{ marginLeft: 10, marginTop: 30 }} variant="primary" type="submit" id="submit">ค้นหา</Button>
                        </Col>
                        <Col>
                            <div style={{ marginTop: 30 }} >
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-success mb-3"
                                    table="table-to-xls"
                                    filename={this.state.partner + ' ' + 'report' + this.state.start_date + '-' + this.state.end_date}
                                    sheet="report-ganesha"
                                    buttonText="Export" />
                            </div>
                        </Col>
                    </Row>

                </Form>
                <br></br>
                <Card>


                    <Table striped bordered id="table-to-xls" >
                        <thead  >
                            {/* <tr>
                                    <td colspan="12" style={{ textAlign: 'center', width: '100%' }}><h3>Partner:จิ้งจกทัก</h3></td>
                                </tr> */}
                            <tr>
                                <th style={{ textAlign: 'center', width: '5%' }}>ลำดับ</th>
                                <th style={{ textAlign: 'center', width: '7%' }}>เลขที่คำสังซื้อ</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>วันที่</th>
                                <th style={{ textAlign: 'center', width: '8%' }}>สินค้า</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>สถานะการชำระเงิน</th>
                                <th style={{ textAlign: 'center', width: '7%' }}>ราคาสินค้ารวม</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>โค้ดส่วนลด</th>
                                <th style={{ textAlign: 'center', width: '5%' }}>ส่วนลด(%)</th>
                                <th style={{ textAlign: 'center', width: '8%' }}>จำนวนเงินที่ลด</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>จำนวนเงินหลังหักส่วนลด</th>
                                <th style={{ textAlign: 'center', width: '8%' }}>ตัดบิล</th>
                                {/* <th style={{ textAlign: 'center', width: '20%' }}>วันที่ตัดบิล</th> */}
                                <th style={{ textAlign: 'center', width: '35%' }}>ใบกำกับภาษี</th>

                            </tr>
                        </thead>
                        <tbody>
                            {this.dataTable()}
                        </tbody>
                        <tfoot>
                            <tr className='total-table'>
                                <td style={{ textAlign: 'center' }}><strong>รวม</strong></td>
                                <td></td><td></td><td></td><td></td>
                                <td style={{ textAlign: 'center' }}><strong>{this.getSumPrice().toFixed(2)}</strong>
                                </td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: 'center' }}><strong>{this.getSumDiscount()}</strong></td>
                                <td style={{ textAlign: 'center' }}><strong>{this.getSumPriceDis().toFixed(2)}</strong></td>
                            </tr>
                        </tfoot>
                    </Table>


                </Card>
            </div>

            )
        }
    }
            dataTable() {
        return _.map(this.state.usersCollection, (data, index) => {

            return (
            <tr key={index}>
                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                <td style={{ textAlign: 'center' }}>
                    {data.order_id}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {(() => {
                        var date = new Date(data.payment_time.toString().slice(0, -1))
                        let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                        return dateMDY
                    })()}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {(() => {
                        switch (data.type) {
                            case 0: return <div style={{ color: 'black' }}>Wallpaper</div>
                            case 1: return <div style={{ color: 'black' }}>Package</div>
                            case 2: return <div style={{ color: 'black' }}>Boxset</div>
                            case 3: return <div style={{ color: 'black' }}>Card</div>
                        }
                    })()}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {(() => {
                        switch (data.order_status) {
                            case 0: return <div style={{ color: '#e74c3c' }}>ยังไม่ชำระเงิน</div>
                            case 1: return <div style={{ color: 'green' }}>ชำระเงินแล้ว</div>
                        }
                    })()}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {data.sum_price.toFixed(2)}
                </td>

                <td style={{ textAlign: 'center' }}>
                    {(() => {
                        if (data.discount_code === '') {
                            data.discount_code = 'Ganesha'
                            return data.discount_code
                        }
                        else {
                            return data.discount_code
                        }
                    })()}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {(() => {
                        // if (data.discount_percent === 0 && data.discount_code === 'JJT52') {
                        //     if (data.type === 0) {
                        //         data.discount_percent = 10
                        //         return data.discount_percent
                        //     } else if (data.type === 1 && data.sum_price === 700) {
                        //         data.discount_percent = 50
                        //         return data.discount_percent
                        //     }
                        //     else if (data.type === 1 && data.sum_price != 700) {
                        //         data.discount_percent = 10
                        //         return data.discount_percent
                        //     }
                        // }
                        // else {

                        // }
                        return data.discount_percent
                    })()}
                </td>
                <td style={{ textAlign: 'center' }}>

                    {(() => {
                        if (data.discount === 0) {
                            data.discount = (data.discount_percent / 100) * data.sum_price
                            data.money_received = data.sum_price - data.discount

                            return data.discount.toFixed(2)
                        } else {
                            return data.discount.toFixed(2)
                        }
                    })()}

                </td>
                <td style={{ textAlign: 'center' }}>
                    {(() => {
                    })()}
                    {data.money_received.toFixed(2)}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {(() => {
                        switch (data.cut_off) {
                            case 1: return <div style={{ color: 'green' }}>สำเร็จ</div>
                            case null: return <div style={{ color: 'red' }}>ยังไม่ตัดบิล</div>
                        }
                    })()}
                </td>
                {/* <td style={{ textAlign: 'center' }}>
                    {(() => {
                        let dates = new Date(data.cut_off_date);
                        var date = utilTime.convertTZ(dates, "Asia/Bangkok")
                        let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                        switch (data.cut_off) {
                            case 1: return dateMDY
                            case null: return <div >-</div>
                        }
                    })()}
                </td> */}
                <td style={{ textAlign: 'center' }}>
                
                <Link  to={{pathname: `/tax_invoice_report`}}  onClick={() => localStorage.setItem("id",data.order_id)} target='_blank'>ใบกำกับภาษี</Link>
                {/* <Link to= '/tax_invoice_report'  params={{ order_id: data.order_id }}>ใบกำกับภาษี</Link> */}
                  {/* <Button onClick={(e) => this.getReport(e,data.order_id)}>ใบกำกับภาษี</Button> */}
                </td>

            </tr>
            )
        })
    }
}
