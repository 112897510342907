import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import Moment from 'moment';
import { Field, reduxForm, reset } from "redux-form";
import {
    Card,
    Col,
    Row,
    Navbar,
    Button,
    Nav,
    Container,
    Jumbotron,
    FormGroup,
    FormControl,
    Form,
    Table
} from "react-bootstrap";
import  utilTime from '../utils/time';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getOrder } from "../services/services"
import * as partnerHttpServices from '../services/services';
export default class Partner extends Component {

    constructor(props) {
        super(props);
        this.state = { usersCollection: [] , codeCollection: [] };

    }
    state = {
        searchText: '',
        query_data:''
    }
    gettoken() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let token = user_info.token
        return token
    }
    getpartnerName() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let partner_name = user_info.data.partner_name
        return partner_name
    }
    getrole() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let role = user_info.data.role
        return role
    }
    getid() {
        let user_info = JSON.parse(localStorage.getItem('user'))
        let id = user_info.data.partner_id
       
        return id
    }
    componentDidMount() { 
 
        if (localStorage.getItem('user') != null) {
            partnerHttpServices.getCode( this.getid()).then((response) => {
                if (response.data.success) {
                    this.setState({ codeCollection: response.data.data })
                }
            })
            partnerHttpServices.getOrder( this.getrole() , this.gettoken()).then((response) => {
                if (response.data.success) {
                    this.setState({ usersCollection: response.data.data })
                }
            })
        } else {
            this.props.history.push('/login')
        }
        window.scrollTo(0, 0)
 
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const jsonData = {
            code: data.get('code'),
            partner: data.get('partner'),
            start_date: data.get('start_date'),
            end_date: data.get('end_date'),
            product: data.get('product'),
            bill: data.get('bill')
        }
        if (localStorage.getItem('user') != null) {
            partnerHttpServices.filterOrder(jsonData, this.gettoken()).then((response) => {
                if (response.data.success) {
                   
                    this.setState({ usersCollection: response.data.data })
                }else{
                    this.setState({ usersCollection: response.data.data })
                }
            })
        } else {
            this.props.history.push('/login')
        }
    };

    onSearchChange = e => {
        this.setState({
            searchText: e.target.value
        });
    }
    getSumOrder() {
        let sum = 0
        _.map(this.state.usersCollection, (data, index) => {
            sum = index + 1
        })
        return sum
    }
    getSumPrice() {
        let sum = 0
        _.map(this.state.usersCollection, data => {
            sum += Number((data.sum_price).toFixed(2))
        })
        return sum
    }
    getSumDiscount() {
        let sum = 0
        _.map(this.state.usersCollection, data => {
            sum += Number((data.discount).toFixed(2))
        })
        return sum.toFixed(2)
    }
    getSumPriceDis() {
        let sum = 0
        _.map(this.state.usersCollection, data => {
            sum += Number((data.money_received).toFixed(2))
        })
        return sum
    }

    onLogout() {
        localStorage.removeItem('user')
        this.props.history.push('/login')
    }
    render() {
        if (this.state.query_data ===1) {
            return (
                <div style={{ paddingLeft: 50, paddingRight: 50 }}>
                    <div><h3 style={{ textAlign: 'center', marginTop: 150 }}>ไม่มีออเดอร์</h3></div>
                </div>
            )
        } else {
            const { handleSubmit } = this.props;
            return (
                <div className="container">
                    <Navbar bg="primary" variant="dark" fixed="top">
                        <Container>
                            <Navbar.Brand href="#home">
                                <h3 style={{ marginLeft: 50 }}>Ganesha 168 Partner</h3>
                            </Navbar.Brand>
                        </Container>
                        <a href="/login" onClick={() => this.onLogout()}style={{ marginRight: 30, color: 'white', textDecorationLine: 'none' }}>Sign Out</a>
                    </Navbar>
                    <br></br><br></br><br></br><br></br>
                    <h2 > Partner:{this.getpartnerName()}</h2>
                    <h3>ออเดอร์ทั้งหมด {this.getSumOrder()}</h3>
                    <Form onSubmit={this.handleSubmit}>
                        <Row >

                            <Col >
                                <Form.Group controlId="code">
                                    <Form.Label style={{ marginLeft: 10 }}>รหัสส่วนลด</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="code"
                                        onChange={this.onSearchChange}
                                        style={{ width: 300 }}
                                    >
                                        <option value=''></option>
                                        {_.map(this.state.codeCollection, (data, index) => (
                                                <option key={`options_key_${index}`}>{ data.discount_code }</option> 
                                   ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col >
                                <Form.Label style={{ marginLeft: 50 }}>วันที่เริ่มต้น</Form.Label>
                                <Form.Control type="date"
                                    onChange={this.onSearchChange}
                                    name="start_date"
                                    ref={(input) => this.query = input}
                                    placeholder="Search..."
                                    style={{ width: 200, marginLeft: 50 }} />
                            </Col>
                            <Col >
                                <Form.Label style={{ marginLeft: 10 }}>วันที่สิ้นสุด</Form.Label>
                                <Form.Control type="date"
                                    onChange={this.onSearchChange}
                                    name="end_date"
                                    ref={(input) => this.query = input}
                                    placeholder="Search..."
                                    style={{ width: 200, marginLeft: 10 }} />
                            </Col>
                            <Col >
                                <Form.Group controlId="bill">
                                    <Form.Label style={{ marginLeft: 10 }}>สถานะการตัดบิล</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="bill"
                                        onChange={this.onSearchChange}
                                        style={{ width: 250 }}
                                    >
                                        <option value=''></option>
                                        <option value="0">ยังไม่ได้ตัดบิล</option>
                                        <option value="1">ตัดบิลแล้ว</option>

                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Button style={{ marginLeft: 10, marginTop: 30 }} variant="primary" type="submit" id="submit">ค้นหา</Button>
                            </Col>
                            <Col>
                            <div  style={{ marginTop: 30 }} >
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-success mb-3"
                                    table="table-to-xls"
                                    filename={this.getpartnerName()+' '+'report' }
                                    sheet="report-ganesha"
                                    buttonText="Export" />
                                    </div>
                            </Col>
                        </Row>

                    </Form>
                    <br></br>
                    <Card>


                        <Table striped bordered id="table-to-xls" >
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '5%' }}>ลำดับ</th>
                                    <th style={{ textAlign: 'center', width: '7%' }}>เลขที่คำสังซื้อ</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>วันที่</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>สินค้า</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>สถานะการชำระเงิน</th>
                                    <th style={{ textAlign: 'center', width: '7%' }}>ราคาสินค้ารวม</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>โค้ดส่วนลด</th>
                                    <th style={{ textAlign: 'center', width: '5%' }}>ส่วนลด(%)</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>จำนวนเงินที่ลด</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>จำนวนเงินหลังหักส่วนลด</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>ตัดบิล</th>
                                    <th style={{ textAlign: 'center', width: '20%' }}>วันที่ตัดบิล</th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.dataTable()}
                            </tbody>
                            <tfoot>
                                <tr className='total-table'>
                                    <td style={{ textAlign: 'center' }}><strong>รวม</strong></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td style={{ textAlign: 'center' }}><strong>{this.getSumPrice().toFixed(2)}</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: 'center' }}><strong>{this.getSumDiscount()}</strong></td>
                                    <td style={{ textAlign: 'center' }}><strong>{this.getSumPriceDis().toFixed(2)}</strong></td>
                                </tr>
                            </tfoot>
                        </Table>
                       

                    </Card>
                </div>

            )
        }
    }
    dataTable() {
        
        return _.map(this.state.usersCollection, (data, index) => {
            return (
                <tr key={index}>
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td style={{ textAlign: 'center' }}>
                        {data.order_id}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                         var date = new Date(data.payment_time.toString().slice(0,-1))
                         let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                         return dateMDY
                        })()}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                            switch (data.type) {
                                case 0: return <div style={{ color: 'black' }}>Wallpaper</div>
                                case 1: return <div style={{ color: 'black' }}>Package</div>
                            }
                        })()}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                            switch (data.order_status) {
                                case 0: return <div style={{ color: '#e74c3c' }}>ยังไม่ชำระเงิน</div>
                                case 1: return <div style={{ color: 'green' }}>ชำระเงินแล้ว</div>
                            }
                        })()}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {data.sum_price.toFixed(2)}
                    </td>

                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                            if (data.discount_code === '') {
                                data.discount_code = 'JJT52'
                                return data.discount_code
                            }
                            else {
                                return data.discount_code
                            }
                        })()}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                            if (data.discount_percent === 0) {
                                if (data.type === 0) {
                                    data.discount_percent = 10
                                    return data.discount_percent
                                } else if (data.type === 1 && data.sum_price === 700) {
                                    data.discount_percent = 50
                                    return data.discount_percent
                                }
                                else if (data.type === 1 && data.sum_price != 700) {
                                    data.discount_percent = 10
                                    return data.discount_percent
                                }
                            }
                            else {
                                return data.discount_percent
                            }
                        })()}
                    </td>
                    <td style={{ textAlign: 'center' }}>

                        {(() => {
                            if (data.discount === 0) {
                                data.discount = (data.discount_percent / 100) * data.sum_price
                                data.money_received = data.sum_price - data.discount

                                return data.discount.toFixed(2)
                            } else {
                                return data.discount.toFixed(2)
                            }
                        })()}

                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                        })()}
                        {data.money_received.toFixed(2)}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                            switch (data.cut_off) {
                                case 1: return <div style={{ color: 'green' }}>สำเร็จ</div>
                                case null: return <div style={{ color: 'red' }}>ยังไม่ตัดบิล</div>
                            }
                        })()}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {(() => {
                            let dates = new Date(data.cut_off_date);
                            var date = utilTime.convertTZ(dates, "Asia/Bangkok")
                            let dateMDY = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                            switch (data.cut_off) {
                                case 1: return dateMDY
                                case null: return <div >-</div>
                            }
                        })()}
                    </td>


                </tr>
            )
        })
    }
}
