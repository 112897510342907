import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Card
} from "react-bootstrap";
import Header from '../page/header';
import * as planetServices from '../services/services'
import Swal from 'sweetalert2'


export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currData: [],
      editedRows : {},
    };
  }


  componentDidMount (){
    //moment2.tz.setDefault("Asia/Bangkok")
    planetServices.getZodiac()
    .then((res) => {
        if(res.data.success){
            let result = res.data.data
            let list_data = []
            result.map((item) => {
                list_data.push({
                    "id" : item.id,
                    "name" : item.name,
                    "prediction" : item.prediction,
                    "prediction_desc" : item.prediction,
                    "video_uri" : item.video_uri,
                })
            })
            console.log(list_data);
            this.setState({
                currData : list_data
            })
        }else{
            // alert('planet data not found!');
            // window.location.reload();
        }

    }).catch(error =>{
        console.log('error fetching data :',error);
    })
  }
    handleInputChange = (id, field, value) => {
    const { editedRows } = this.state;
  
    const updatedRow = { ...editedRows[id] };
    updatedRow[field] = value;
  
    this.setState(prevState => ({
      editedRows: {
        ...prevState.editedRows,
        [id]: updatedRow
      }
    }));
  };

  handleSave = () => {
    const { editedRows, currData } = this.state;
  
    const updatedData = currData.map(item => {
      const editedRow = editedRows[item.id];
      if (editedRow) {
        return {
          ...item,
          ...editedRow
        };
      }
      return item;
    });
    
    const jsonData = updatedData.map(item => ({
        id: item.id,
        name: item.name,
        prediction: item.prediction,
        prediction_desc: item.prediction_desc,
        video_uri: item.video_uri,
      }));
  
    planetServices.updateZodiac(jsonData)
      .then((res) => {
        if (res.data.success) {
          console.log('Edit success');
          Swal.fire(
            'Edit Success!',
            'You clicked the button!',
            'success'
          );
        } else {
          console.log('Edit fail', res);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="">Why do I have this issue?</a>'
          });
        }
      })
      .catch(error => {
        console.error('Error updating planet:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  
   
    this.setState({
      currData: updatedData,
      editedRows: {}
    });
  };

  render() {
    const { currData,editedRows } = this.state;

    return (
      <Container>
        <div className="header">
            <Header/>
        </div>
        <div className='formeditcard'>
        <Card>
        <div className='formedit'>
            <h2>Zodiac</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>หมายเลขราศี</th>
              <th>ราศี</th>
              <th>คำทำนาย (สรุปภาพรวม)</th>
              <th>คำทำนาย (อธิบาย)</th>
              <th>Link คลิป</th>
            </tr>
          </thead>
          <tbody>
            {currData.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>
                 {item.name}
                </td>
                <td>
                <textarea
                    value={editedRows[item.id] && editedRows[item.id].prediction !== null ? editedRows[item.id].prediction : item.prediction || ''}
                    style={{width: "100%"}}
                    rows={3}
                    onChange={e =>
                     this.handleInputChange(item.id, "prediction", e.target.value)
                    }
                />
                </td>
                <td>
                <textarea
                    value={editedRows[item.id] && editedRows[item.id].prediction_desc !== null ? editedRows[item.id].prediction_desc : item.prediction_desc || ''}
                    style={{width: "100%"}}
                    rows={3}
                    onChange={e =>
                     this.handleInputChange(item.id, "prediction_desc", e.target.value)
                    }
                />
                </td>
                <td>
                  <input
                    type="text"
                    value={editedRows[item.id] && editedRows[item.id].video_uri !== null ? editedRows[item.id].video_uri : item.video_uri || ''}
                    style={{width: "100%"}}
                    onChange={e =>
                      this.handleInputChange(item.id, "video_uri", e.target.value)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button variant="primary" onClick={this.handleSave}>
          บันทึก
        </Button>
        </div>
        </Card>
        </div>
      </Container>
    );
  }
}
