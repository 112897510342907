import axios from "axios";
const QueryString = require("qs");
const ROOT_URL = "https://api.ganesha168.com";
// const ROOT_URL = "http://localhost:1689";
//const ROOT_URL = "http://localhost:1147";

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { 'x-access-token': user.accessToken };
  } else {
    return {};
  }
}
export const login = (data) => {
  return axios.post(`${ROOT_URL}/bof/login/`, data)
    .then((res) => {
      localStorage.setItem("user", JSON.stringify(res.data));
      if (res.data.success === true) {
        alert("login success");
        if (res.data.data.role === 0) {
          window.location = "/menu";
        } else if (res.data.data.role === 1) {
          window.location = "/table";
        } else {
          window.location = "/login";
        }
      } else {
        alert("login failed");
      }
    })
    .catch(function (error) {
      console.log("err: ", error);
    });
};
export const getOrder = (value, token, callback) => {
  return axios.get(`${ROOT_URL}/partner/order_partner`,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token,
      'roles':value
    },
  })
};
export const filterOrder = (value, token, callback) => {
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/partner/partner_search`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    },
  })
};

export const getOrderAdmin = (value, token, callback) => {
  return axios.get(`${ROOT_URL}/admin/get_all_order`,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token,
      'roles':value
    },
  })
};
export const filterOrderAdmin = (value, token, callback) => {
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/admin/admin_search`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    },
  })
};

export const getCode = (value, callback) => {
  return axios.get(`${ROOT_URL}/admin/partner_code/`+value,{

  })
};
export const getPartnerName = (value, callback) => {
  return axios.get(`${ROOT_URL}/admin/partner_name`,{

  })
};
export const getData = (value, callback) => {
  return axios.get(`${ROOT_URL}/admin/get_data/`+value,{

  })
};

export const editData = (value, callback) => {
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/admin/edit/`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
};

export const getCodeStatus = (value, callback) => {
  return axios.get(`${ROOT_URL}/admin/get_code_status/`+value,{

  })
};

export const editCodeStatus = (value, callback) => {
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/admin/edit_code_status/`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
};

export const boardcast = (value, callback) => {
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/admin/boardcast/`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
};
export const boardcaststar = (value, callback) => {
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/admin/start_boardcast/`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
};

export const ShowTable = () => {
  return axios.get(`${ROOT_URL}/admin/show_table/`,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
};
export const controlPackage = (value) => {
   const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/admin/package_control/`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
};
export const getOrderDetail = (value) => {
  return axios.get(`${ROOT_URL}/admin/get_order_detail/`+value,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
};

export const getPlanet = () => {
  return axios.get(`${ROOT_URL}/planet/get_planets/`,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const updatePlanet = (value) =>{
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/planet/update/`,body,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const getZodiac = () => {
  return axios.get(`${ROOT_URL}/zodiac/get/`,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const updateZodiac = (value) => {
  const body = QueryString.stringify(value);
  return axios.post(`${ROOT_URL}/zodiac/update/`,body,{
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}