import React, { Component } from 'react';
import * as partnerHttpServices from '../services/services';
import _ from 'lodash';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';

// import Swal from 'sweetalert2'
import {
    // Col,
    // Row,
    Navbar,
    // Button,
    Container,
    // Form,
    Table
} from 'react-bootstrap';
import Header from '../page/header';
export default class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            dataCollection: [],
        })
    }

    componentDidMount() {
        partnerHttpServices.ShowTable()
            .then((res) => {
                this.setState({ dataCollection: res.data.data })
            })


        window.scrollTo(0, 0)
    }

    onSearchChange = (e) => {
        this.setState({
            search: e.target.value
        });
    }

    onLogout() {
        localStorage.removeItem('user')
        this.props.history.push('/login')
    }

    dataTable() {
        return _.map(this.state.dataCollection, (data, index) => {
            // console.log(data)
            return (
                <tr key={index}>
                    <td style={{ textAlign: 'center' }}>
                        {data.wallpaper_id}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {data.name}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        {data.count}
                    </td>
                </tr>
            )
        })
    }

    getSumCount() {
        let sum = 0
        _.map(this.state.dataCollection, (data) => {
            sum += Number((data.count))
        })
        return sum
    }
    render() {
        return (
            <div className="container">
                        <div className='header'>
                            <Header />
                        </div>

                <h2 style={{ marginTop: '6rem' }}>Wallpapers detail</h2>
                <h3 style={{ marginTop: '1rem' }}>จำนวนการสั่งซื้อทั้งหมด {this.getSumCount()} รายการ</h3>
                <br></br>

                <Table striped bordered id="table-to-xls" >
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center', width: '5%' }}>ID</th>
                            <th style={{ textAlign: 'center', width: '80%' }}>รายการ</th>
                            <th style={{ textAlign: 'center', width: '15' }}>จำนวนการสั่งซื้อ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.dataTable()}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ textAlign: 'center' }}><strong>รวม</strong></td>
                            <th></th>
                            <th style={{ textAlign: 'center', width: '15' }}>{this.getSumCount()}</th>
                        </tr>
                    </tfoot>
                </Table>

                <div style={{ marginTop: 100, marginBottom: 100, width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                    <BarChart />
                    <PieChart />
                </div>


                {/* <div style={{ marginTop: 150, width: '100%' }} >
                    <BarChart />
                </div>

                <div style={{ marginTop: 150, width: '100%' }} >
                    <LineChart />
                </div>
                <div style={{ marginTop: 150, width: '100%' }} >
                    <PieChart />
                </div> */}

            </div>

        )
    }
}