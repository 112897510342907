import { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Tab,
    Tabs,
    Card,
    Table
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import * as AdminService from '../services/services';
import { useLocation,useParams } from 'react-router-dom';
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import '../App.css';
import { grey } from "@mui/material/colors";
import { margin } from "@mui/system";
const ReactPdfPrint = () => {
    const componentRef = useRef()
    const id = parseInt(localStorage.getItem("id"), 10) || null;
    const [order_id, setOrderId] = useState(id);
    const [order, setOrder] = useState([]);
    const [type_name, setTypeName] = useState('');
    const [order_discount, setOrderDiscount] = useState('');
    const [money_received, setMoneyReceived] = useState('');
    const [date, setDate] = useState('');
    const [order_type, setOrderType] = useState('');
    const [report_id, setReportId] = useState('');
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: report_id,
        // onAfterPrint: () => alert('Print success')

    })
        useEffect(() => {
            if(order.length==0){
                let data;
                AdminService.getOrderDetail(order_id)
                  .then((res) => {
                    data = res.data.data[0]
                    setOrder(data)
                    setOrderType(data.type)
                    setTypeName(data.type_name.name)
                    setOrderDiscount(data.money_received)
                    setReportId(data.report_id)
                    const dates = new Date(data.payment_time)
                    const result = dates.toLocaleDateString('th-TH', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })
                    setDate(result)
                   
                  })
                  .catch((err) => {});

            }else{
                // handlePrint()
            }
    }, [type_name]);
    return (  
        <>
          <Button onClick={handlePrint} style={{float : "right" ,margin:20}}>Print ใบกำกับภาษี</Button>

            <div ref={componentRef} style={{ width: '100%', height: window.innerHeight, marginLeft:30}}>
                <br/> <br/>
               <Row>
                   <Col>
               <h5 >บริษัท มูเทคโนโลยี จำกัด (สำนักงานใหญ่)</h5>
              <div >206/160 หมู่บ้าน เดอะเมทโทร สาทร ถนนกัลปพฤกษ์</div>
              <div >แขวงบางหว้า เขตภาษีเจริญ กรุงเทพมหานคร 10160</div>
              <div >นิติบุคคลเลขที่ 0105565035511</div>
              </Col>
                  <Col>
                  <div className="rectangle" ><div style={{textAlign:"center",marginTop:15}}>ใบกำกับภาษีอย่างย่อ</div></div>
                  <div style={{marginTop:15,marginLeft:140}}>{date}</div>
                  <div style={{marginTop:15,marginLeft:140,display:"inline"}}>เลขที่ :</div><span style={{marginLeft:10,display:"inline"}}> {report_id}</span>
                  </Col>
              </Row>
              <br/>
                <Table striped bordered  style={{width:"92%"}}>
                        <thead  >
                            <tr>
                                <th style={{ textAlign: 'center', width: '20%' }}>ลำดับที่</th>
                                <th style={{ textAlign: 'center', width: '30%' }}>รายละเอียดสินค้า</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>จำนวน</th>
                                <th style={{ textAlign: 'center', width: '20%' }}>ราคา/หน่วย</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>จำนวนเงิน</th>
                            </tr>
                        </thead>
                        <tbody >
                        <tr>
                            <td style={{ textAlign: 'center' }}>1</td>
                            {(() => {
                                if(order_type==0){
                                  return  <td style={{ textAlign: 'center' }}>Wallpaper {type_name}</td>
                                }else{
                                   return <td style={{ textAlign: 'center' }}>Package {type_name}</td>
                                }
                                
                    })()}
                           
                            <td style={{ textAlign: 'center' }}>1</td>
                            <td style={{ textAlign: 'center' }}>{order_discount}</td>
                            <td style={{ textAlign: 'center' }}>{order_discount}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr className='total-table'>
                                
                                <td style={{ textAlign: 'right' }}  colSpan={4}><strong>(ราคารวมภาษีมูลค่าเพิ่ม) รวมเป็นเงิน</strong>
                                </td>
                                <td style={{ textAlign: 'center' }}><strong>{order_discount}</strong></td>
                            </tr>
                        </tfoot>
                    </Table>
                    <br/>
                     <Row>
                         <Col><br/></Col>
                        <Col>  <div style={{ textAlign: 'center' }} >(ประไพ    ไผ่งาม)</div>
                                  <div style={{ textAlign: 'center' }}>ผู้รับเงิน</div>  
                            </Col>
                     </Row>
            </div>
            
        </>

    )


}
export default ReactPdfPrint;
